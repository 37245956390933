<template>
  <KTPortlet>
    <template v-slot:title>
      <!-- <b-overlay :show="loading"> -->
      <h3 class="kt-portlet__head-title">
        {{ headText.pdfName }} / {{ headText.creationTime }}
      </h3>
    </template>
    <template v-slot:body>
      <b-overlay :show="loading">
        <b-tabs v-model="tabIndex" content-class="mt-3">
          <b-tab title="Information">
            <div class="mb-5">
              <Information
                :initData="informationData"
                :options="options"
                ref="Information"
              ></Information>
              <FreightCost
                :initData="informationData.freightCost"
                :options="options"
                ref="FreightCost"
              ></FreightCost>

              <SelectPurchase
                v-if="informationData.freightCost"
                :initData="informationData.selectPurchase"
                :unitFreightNet="
                  informationData.freightCost.unit_freight_net.model
                "
                v-on:updateTable="updateTable"
                ref="SelectPurchase"
              ></SelectPurchase>
            </div>
          </b-tab>
          <b-tab title="Logs">
            <SimpleTable
              :items="items"
              :fields="fields"
              :tableId="'logs'"
              style="margin-top: 20px; min-height: 200px"
            ></SimpleTable>
          </b-tab>
        </b-tabs>
        <div class="console" v-if="ifConsole">
          <b-overlay :show="loading">
            <b-button
              variant="primary"
              class="mr-2"
              :disabled="saveDisabled"
              @click="save()"
              >Save
              <i class="far fa-save"></i>
            </b-button>
            <router-link
              :to="{
                name: 'upload-invoice-list'
              }"
              class="btn btn-secondary"
              >Back to List
              <b-icon icon="reply-fill" aria-hidden="true"></b-icon
            ></router-link>
          </b-overlay>
        </div>
      </b-overlay>
    </template>
  </KTPortlet>
</template>

<script>
import KTPortlet from '@/views/content/Portlet';
import SimpleTable from '@/components/Table/Simple.vue';
import Information from '@/views/content/Detail/UploadInvoice/Information/Index.vue';
// import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import apiBus from '@/common/apiBus/index';
// import { mapState, mapGetters } from 'vuex';
import JwtService from '@/common/services/jwt';
import FreightCost from '@/views/content/Detail/UploadInvoice/Information/FreightCost.vue';
import SelectPurchase from '@/views/content/Detail/UploadInvoice/Information/SelectPurchase.vue';

export default {
  name: 'SaaSPuchaseInvoiceFileDetail',
  components: {
    KTPortlet,
    SimpleTable,
    Information,
    FreightCost,
    SelectPurchase
  },
  data() {
    return {
      id: null,
      // 标题
      headText: {
        pdfName: '',
        creationTime: ''
      },
      tabIndex: 0,
      loading: false,
      saveDisabled: false,
      informationData: {},
      ifConsole: false,
      total_invoice_net: 0,
      // logs 数据
      items: [],
      fields: [
        {
          key: 'created_at',
          label: 'Time'
        },
        {
          key: 'system_user',
          label: 'System User'
        },
        {
          key: 'log_action',
          label: 'Action'
        },
        {
          key: 'detail',
          label: 'Details'
        }
      ],

      options: {
        supplier_id: [],
        currency: [
          { label: 'EUR', value: 'EUR' },
          { label: 'CNY', value: 'CNY' },
          { label: 'HUF', value: 'HUF' }
        ],
        refundable: [
          { label: 'Yes', value: 1 },
          { label: 'No', value: 2 },
          { label: 'Uncertain', value: 3 }
        ],
        taxRate1: [
          { label: '0%', value: 0 },
          { label: '19%', value: 0.19 }
        ],
        taxRate2: [
          { label: '0%', value: 0 },
          { label: '7%', value: 0.07 },
          { label: '19%', value: 0.19 }
        ]
      }
    };
  },

  methods: {
    handleData(data) {
      let handle_data = {};
      let {
        invoice_no,
        status,
        invoice_date,
        refundable,
        purchase_overview_id,
        supplier_id,
        total_invoice_gross,
        currency,
        total_vat,
        freight_cost,
        enter_mode,
        tax_rate,
        total_freight_net,
        total_freight_gross,
        purchase_invoice,
        select
      } = data;
      console.log(invoice_date, purchase_overview_id, refundable);
      handle_data = {
        information: {
          invoice_no: {
            type: 'input',
            text: 'Invoice No.',
            model: invoice_no,
            disabled: false
          },
          status: {
            type: 'text',
            text: 'Status',
            display_text: status,
            class: 'status-text status',
            text_class: `text color_${status ? status.replace(' ', '_') : ''}`
          },
          invoice_date: {
            type: 'date',
            text: 'Purchase Date',
            model: invoice_date,
            disabled: false
          },
          refundable: {
            type: 'select',
            text: 'Tax Refund',
            model: refundable,
            disabled: false,
            options: this.options.refundable
          },
          supplier_id: {
            type: 'select',
            text: 'Supplier Name',
            model: supplier_id,
            disabled: false,
            options: this.options.supplier_id
          },
          invoice_document: {
            type: 'view',
            text: 'Invoice Document',
            click: this.uploadcheck,
            bottomText: 'View PDF'
          },
          // 计算数据
          total_quantity: {
            type: 'input',
            text: 'Total Quantity',
            model: purchase_invoice.length,
            disabled: true
          },
          total_invoice_net: {
            type: 'input',
            text: 'Total Invoice Net',
            model: this.total_invoice_net,
            disabled: true
          },
          total_invoice_gross: {
            type: 'input',
            text: 'Total Invoice Gross',
            model: total_invoice_gross || 0,
            disabled: true
          },
          total_vat: {
            type: 'input',
            text: 'Total VAT',
            model: total_vat || 0,
            disabled: true
          },
          currency: {
            type: 'select',
            text: 'Currency',
            model: currency,
            disabled: false,
            options: this.options.currency
          }
        },
        freightCost: {
          freight_cost: {
            type: 'radio',
            md: 12,
            text: 'Freight Cost',
            model: freight_cost || 1,
            change: this.switchState,
            options: [
              { label: 'No', value: 0 },
              { label: 'Yes', value: 1 }
            ],
            disabled: true
          },
          enter_mode: {
            type: 'radio',
            md: 12,
            show: true,
            text: 'Enter Mode',
            model: enter_mode || 1,
            options: [
              { label: 'Net', value: 0 },
              { label: 'Gross', value: 1 }
            ],
            disabled: true,
            change: this.switchEnter
          },
          tax_rate: {
            type: 'select',
            md: 12,
            show: true,
            text: 'Tax Rate',
            model: tax_rate || 0.19,
            disabled: false,
            options: this.options.taxRate1,
            change: this.inputTaxRate
          },
          total_freight_net: {
            type: 'input',
            text: 'Total Freight Net',
            model: total_freight_net || 0,
            disabled: true,
            show: true,
            change: this.inputTotalFreightNet
          },
          total_freight_gross: {
            type: 'input',
            text: 'Total Freight Gross',
            model: total_freight_gross || 0,
            disabled: false,
            show: true,
            change: this.inputTotalFreightGross
          },
          unit_freight_net: {
            type: 'input',
            text: 'Unit Freight Net',
            model: this.turnDollar(
              this.turnNumbers(total_freight_net) / purchase_invoice.length
            ),
            disabled: true,
            show: true
          },
          unit_freight_gross: {
            type: 'input',
            text: 'Unit Freight Gross',
            model: this.turnDollar(
              this.turnNumbers(total_freight_gross) / purchase_invoice.length
            ),
            disabled: true,
            show: true
          }
        },
        selectPurchase: {
          purchase_invoice,
          select
        }
      };
      console.log(handle_data);
      return handle_data;
    },

    // 欧元转数字
    turnNumbers(i) {
      let data;
      switch (typeof i) {
        case 'string':
          data = i.replace('.', '').replace(',', '.');
          break;
        case 'number':
          data = i;
          break;
      }
      return data;
    },

    // 数字转欧元
    turnDollar(num) {
      var p = num.toFixed(4).split('.');
      var chars = p[0].split('').reverse();
      var newstr = '';
      var count = 0;
      for (let x in chars) {
        count++;
        if (count % 3 == 1 && count != 1) {
          newstr = chars[x] + '.' + newstr;
        } else {
          newstr = chars[x] + newstr;
        }
      }
      return newstr + ',' + p[1];
    },

    // 当总数量更新的时候
    updateTable(val) {
      console.log(val);
      if (val) {
        this.informationData.information.total_quantity.model = val.length;
        let { freightCost } = this.informationData;
        let {
          total_freight_net,
          total_freight_gross,
          unit_freight_net,
          unit_freight_gross
        } = freightCost;
        // 刷新 Unit Freight Net 和 Unit Freight Gross
        unit_freight_net.model = this.turnDollar(
          this.turnNumbers(total_freight_net.model) /
            this.informationData.information.total_quantity.model
        );
        unit_freight_gross.model = this.turnDollar(
          this.turnNumbers(total_freight_gross.model) /
            this.informationData.information.total_quantity.model
        );
        // 计算 Total Invoice Net 和 Total Invoice Gross 和 Total VAT
        let total_net_amount = 0,
          total_gross_amount = 0,
          total_vat_amount = 0;
        val.forEach(item => {
          total_net_amount += this.turnNumbers(item.total_net_amount) - 0;
          total_gross_amount += this.turnNumbers(item.total_gross_amount) - 0;
          total_vat_amount += this.turnNumbers(item.total_vat_amount) - 0;
        });
        console.log(total_net_amount);
        console.log(total_gross_amount);
        console.log(total_vat_amount);
      }
    },

    // 切换Freight Cost的操作
    switchState(e) {
      let { freightCost } = this.informationData;
      let {
        enter_mode,
        tax_rate,
        total_freight_net,
        total_freight_gross,
        unit_freight_net,
        unit_freight_gross
      } = freightCost;
      switch (e) {
        case 0:
          enter_mode.show = false;
          tax_rate.show = false;
          total_freight_net.show = false;
          total_freight_gross.show = false;
          unit_freight_net.show = false;
          unit_freight_gross.show = false;
          break;
        case 1:
          enter_mode.show = true;
          tax_rate.show = true;
          total_freight_net.show = true;
          total_freight_gross.show = true;
          unit_freight_net.show = true;
          unit_freight_gross.show = true;
          break;
      }
    },
    // 切换Enter Mode的操作
    switchEnter(e) {
      let { freightCost } = this.informationData;
      let { total_freight_net, total_freight_gross } = freightCost;
      console.log(total_freight_net, total_freight_gross);
      switch (e) {
        case 0:
          total_freight_net.disabled = false;
          total_freight_gross.disabled = true;
          break;
        case 1:
          total_freight_net.disabled = true;
          total_freight_gross.disabled = false;
          break;
      }
    },
    // Freight Cost 里 Tax Rate 计算
    inputTaxRate(e) {
      let { freightCost } = this.informationData;
      let {
        total_freight_net,
        total_freight_gross,
        unit_freight_net,
        unit_freight_gross,
        enter_mode
      } = freightCost;
      let amount = 0;
      switch (enter_mode.model) {
        case 0:
          amount = this.turnNumbers(total_freight_net.model) * (1 + e);
          unit_freight_net.model = this.turnDollar(
            this.turnNumbers(total_freight_net.model) /
              this.informationData.information.total_quantity.model
          );
          unit_freight_gross.model = this.turnDollar(
            amount / this.informationData.information.total_quantity.model
          );
          total_freight_gross.model = this.turnDollar(amount);
          break;
        case 1:
          amount = this.turnNumbers(total_freight_gross.model) / (1 + e);
          unit_freight_net.model = this.turnDollar(
            amount / this.informationData.information.total_quantity.model
          );
          unit_freight_gross.model = this.turnDollar(
            this.turnNumbers(total_freight_gross.model) /
              this.informationData.information.total_quantity.model
          );
          total_freight_net.model = this.turnDollar(amount);
          break;
      }

      this.updateTable();
    },
    // Freight Cost 里 Total Freight Net 计算
    inputTotalFreightNet(e) {
      let { freightCost } = this.informationData;
      let {
        tax_rate,
        total_freight_gross,
        unit_freight_net,
        unit_freight_gross
      } = freightCost;

      let amount = this.turnNumbers(e) * (1 + tax_rate.model);

      unit_freight_net.model = this.turnDollar(
        this.turnNumbers(e) /
          this.informationData.information.total_quantity.model
      );

      unit_freight_gross.model = this.turnDollar(
        amount / this.informationData.information.total_quantity.model
      );

      total_freight_gross.model = this.turnDollar(amount);
      this.updateTable();
    },
    // Freight Cost 里 Total Freight Gross 计算
    inputTotalFreightGross(e) {
      let { freightCost } = this.informationData;
      let {
        tax_rate,
        total_freight_net,
        unit_freight_net,
        unit_freight_gross
      } = freightCost;
      let amount = this.turnNumbers(e) / (1 + tax_rate.model);

      unit_freight_net.model = this.turnDollar(
        amount / this.informationData.information.total_quantity.model
      );

      unit_freight_gross.model = this.turnDollar(
        this.turnNumbers(e) /
          this.informationData.information.total_quantity.model
      );

      total_freight_net.model = this.turnDollar(amount);
      this.updateTable();
    },

    uploadcheck() {
      var childWin = window.open(
        `/rest/api/purchase/print-purchase-invoice?purchase_invoice_file_id=${
          this.id
        }&token=${JwtService.getToken()}`,
        'newwindow',
        'height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no'
      );
      childWin.onload = function() {
        childWin.print();
      };
    },
    calculateData(n, s) {
      /*处理展示pdf链接 */
      console.log(s.$refs.SelectPurchase);

      // s.$refs.SelectPurchase.init(n);
    },
    initFn() {
      this.loading = true;
      apiBus.uploadInvoice
        .getUploadPurchaseInvoiceFileInfo({ id: this.id })
        .then(data => {
          // console.log(data.data.data);
          this.headText.pdfName = data.data.data.file_name;
          this.headText.creationTime = data.data.data.created_at;
          this.informationData = this.handleData(data.data.data);
          console.log(this.$refs);
          this.calculateData(this.informationData.purchase_invoice, this);
          this.loading = false;
          this.ifConsole ? '' : (this.ifConsole = true);
        })
        .catch(error => {
          this.loading = false;
          this.ifConsole ? '' : (this.ifConsole = true);
          console.log(error);
        });
    },
    save() {
      // this.total_invoice_net = 111;
      let { information, freightCost } = this.informationData;
      let {
        invoice_no,
        invoice_date,
        refundable,
        supplier_id,
        currency
      } = information;
      let { tax_rate, total_freight_net, total_freight_gross } = freightCost;
      let data = {
        id: this.id,
        invoice_no: invoice_no.model,
        invoice_date: invoice_date.model,
        refundable: refundable.model,
        supplier_id: supplier_id.model,
        currency: currency.model,
        tax_rate: tax_rate.model,
        total_freight_net: total_freight_net.model,
        total_freight_gross: total_freight_gross.model,
        purchase_invoice: []
      };
      let SelectPurchase = this.$refs.SelectPurchase.items;
      let items = [];
      SelectPurchase.forEach(item => {
        console.log(item);
        items.push({
          net_amount: item.net_amount,
          vat_amount: item.vat_amount,
          gross_amount: item.gross_amount,
          freight_amount: item.total_freight,
          tax_rate: item.tax_rate,
          product_qty: item.product_qty,
          purchase_overview_id: item.purchase_overview_id
        });
      });
      data.purchase_invoice = items;
      console.log(data);
      this.loading = true;
      this.saveDisabled = true;
      apiBus.uploadInvoice
        .saveUploadPurchaseInvoiceDetail(data)
        .then(data => {
          console.log(data);
          this.loading = false;
          this.saveDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch(error => {
          this.loading = false;
          this.saveDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    init() {
      this.id = this.$route.params.id;
      apiBus.uploadInvoice
        .getPurchaseInvoiceFileLog({ id: this.id })
        .then(data => {
          this.items = data.data.data;
        })
        .catch(error => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
      apiBus.purchase
        .getSupplierList()
        .then(data => {
          let options = [];
          Object.keys(data.data.data.supplier_list).forEach(item => {
            options.push({
              label: item,
              value: item.replace(/ /g, '_'),
              index: 1,
              disabled: true
            });
            // console.log(item);
            data.data.data.supplier_list[item].forEach(x => {
              options.push({
                label: x.label,
                value: x.value,
                index: 2
              });
            });
          });
          this.options.supplier_id =
            data.data.data.supplier_list['Other Supplier'];
          // console.log(options);
        })
        .catch(error => {
          console.log(error);
        });

      this.initFn();
    }
  },

  computed: {},

  mounted() {
    this.init();
  },
  watch: {
    'options.supplier_id'(n) {
      if (Object.keys(this.informationData).length != 0) {
        console.log('更新');
        this.informationData.information.supplier_id.options = n;
      }
    },
    SelectPurchase(n) {
      console.log(n);
    }
  }
};
</script>
<style scoped></style>
